/* palanquin-100 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Palanquin';
	font-style: normal;
	font-weight: 100;
	src: url('./palanquin-v13-latin/palanquin-v13-latin-100.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('./palanquin-v13-latin/palanquin-v13-latin-100.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-200 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Palanquin';
	font-style: normal;
	font-weight: 200;
	src: url('./palanquin-v13-latin/palanquin-v13-latin-200.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('./palanquin-v13-latin/palanquin-v13-latin-200.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-300 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Palanquin';
	font-style: normal;
	font-weight: 300;
	src: url('./palanquin-v13-latin/palanquin-v13-latin-300.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('./palanquin-v13-latin/palanquin-v13-latin-300.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Palanquin';
	font-style: normal;
	font-weight: 400;
	src: url('./palanquin-v13-latin/palanquin-v13-latin-regular.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('./palanquin-v13-latin/palanquin-v13-latin-regular.woff')
			format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-500 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Palanquin';
	font-style: normal;
	font-weight: 500;
	src: url('./palanquin-v13-latin/palanquin-v13-latin-500.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('./palanquin-v13-latin/palanquin-v13-latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-600 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Palanquin';
	font-style: normal;
	font-weight: 600;
	src: url('./palanquin-v13-latin/palanquin-v13-latin-600.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('./palanquin-v13-latin/palanquin-v13-latin-600.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* palanquin-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Palanquin';
	font-style: normal;
	font-weight: 700;
	src: url('./palanquin-v13-latin/palanquin-v13-latin-700.woff2')
			format('woff2'),
		/* Chrome 36+, Opera 23+, Firefox 39+ */
			url('./palanquin-v13-latin/palanquin-v13-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
