@import '../../../libs/assets/src/lib/fonts/palanquin.css';
@import '../../../libs/assets/src/lib/fonts/poppins.css';

body {
	font-family: 'Palanquin', sans-serif;
	margin: 0;
	overflow-x: hidden;
	overflow-y: hidden;
	height: calc(var(--doc-height));
	--notch-inset-top: 0px;
	--notch-inset-right: 0px;
	--notch-inset-bottom: 0px;
	--notch-inset-left: 0px;
}

#root {
	height: 100%;
	position: relative;
}

#cookiescript_injected_fsd {
	top: var(--mopla-safe-area-top, 0) !important;
	height: calc(100% - var(--mopla-safe-area-top, 0px)) !important;
}
